import React, { createContext, useContext, useState, useEffect, useCallback } from "react";
import { getService } from "../../services/fetch.js";

const ServiceStatusContext = createContext();

export const useServiceStatus = () => {
    return useContext(ServiceStatusContext);
};

const ServiceStatusProvider = ({ children }) => {
    const [serviceStatus, setServiceStatus] = useState({
        isActive: false,
        title: "",
        description: "",
    });
    const [isLoading, setIsLoading] = useState(false);
    const [hasFetched, setHasFetched] = useState(false);

    const checkServiceStatus = useCallback(async () => {
        try {
            if (!hasFetched) {
                setIsLoading(true);
            }
            const { content } = await getService("/settings/maintenance");
            if (content) {
                setServiceStatus({
                    isActive: content.active,
                    title: content.title || "Service Unavailable",
                    description: content.text || "The service is currently unavailable. Please try again later.",
                });
            }
        } catch (error) {
            setServiceStatus({
                isActive: false,
                title: "Service Unavailable",
                description: "The service is currently unavailable. Please try again later.",
            });
        } finally {
            setIsLoading(false);
            setHasFetched(true);
        }
    }, [hasFetched]);

    useEffect(() => {
        checkServiceStatus(); // Initial service check

        const intervalId = setInterval(checkServiceStatus, 50000); // Ping every 2 minutes

        return () => clearInterval(intervalId);
    }, [checkServiceStatus]);

    return (
        <ServiceStatusContext.Provider value={{ ...serviceStatus, isLoading }}>
            {children}
        </ServiceStatusContext.Provider>
    );
};

export default ServiceStatusProvider;