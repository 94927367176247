import React from 'react';
import "./Service.scss";

const ServicePage = ({ title, description }) => {

  return (
    <div className="service-page">
    <div className="service-content">
      <h1 className="service-title">{title}</h1>
      <p className="service-description">{description}</p>
    </div>
  </div>
  );
};

export default ServicePage;
