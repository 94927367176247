import React from 'react';
import { Provider } from 'react-redux';
import MainRouter from './Router';
import store from './services/store';
import { useServiceStatus } from './components/Service/ServiceProvider';
import Loader from './components/common/Loader';
import ServicePage from './components/Service';
import 'react-toastify/dist/ReactToastify.css';
import './i18n';
import './extendDate';

function App() {
    const { isActive, title, description, isLoading } = useServiceStatus();

    if (isLoading) {
        return <Loader className="is-fixed" />;  // Show loading state if waiting for service status
    }

    if (isActive) {
        return (
            <ServicePage title={title} description={description} />
        );
    }

    return (
        <Provider store={store}>
            <MainRouter />
        </Provider>
    )
}

export default App;
